import axios from 'axios';
import { trackers } from '@rmm/error-tracker';
import { CueManager } from '../routines/CueManager';
import { oldVideoStart } from './logger';

/* eslint-disable no-restricted-globals */
const IMAGE_DOMAIN = 'anvato.images.psg.nexstardigital.net';
const RE_JSON = /\([{[].*[}\]]\)$/;
const adText = 'Advertisement. Your video will resume after the ad break.';

const { SimpleErrorTracker } = trackers;

// Flag to determine whether we've reset playheadPosition yet or not.
let playheadReset = false;
// Flag to determine whether we've captured how off currentTime is this time.
let timeOffsetUpdated = false;
// We use this to adjust the playheadPosition by whatever offset it has the first time it checks currentTime after preroll.
let timeOffset = 0;
export const analyticsPayloadDict = {};
export const nielsenDict = {};
export const ANALYTICS_ADPODTYPE = {
	PREROLL: 'preroll',
	POSTROLL: 'postroll',
	MIDROLL: 'midroll',
};
const SOCIAL_MEDIA_TYPES = {
	tw: 'twitter',
	email: 'email',
	whatsapp: 'whatsapp',
	messenger: 'messenger',
};
let adHeartbeatID;
// How many seconds apart ad heartbeats should be dispatched
const adHeartbeatInterval = 1;
export const ERROR_TRACKER_RENDER_GROUP = {
	general: 'OVP General Tasks',
	adInit: 'OVP Ad Initialization',
	adServe: 'OVP Ad Serving',
	videoInit: 'OVP Video Initialization',
	videoServe: 'OVP Video Serving',
};

/* A list of events that shouldn't be fired on livestreams */
const LIVESTREAM_EVENT_DENY_LIST = [
	'seekStart',
	'seekEnd',
	'videoFirstQuartile',
	'videoMidPoint',
	'videoThirdQuartile',
];

/* Helper function that returns if the video_id received by the video player indicates an Anvato feed */
export const isAnvatoFeed = (video_id) => video_id?.type === 'feed';

/* Helper function that returns current watch time for the player. Different rules for live streams and VOD */
export function getCurrentTime(player) {
	let currentTime = player.currentTime();
	if (player.isLivestream) {
		const timeNow = player.currentTime();
		currentTime = timeNow - player.initTime;
	}
	return currentTime;
}

/* Helper function that tries to return parsed JSON value of given string. */
export function parse_json(json_string) {
	let result = json_string;
	try {
		result = JSON.parse(json_string);
	} catch (err) {
		if (err instanceof SyntaxError) {
			// const result = json_string;
		} else {
			throw err;
		}
	}
	return result;
}

/* Helper function that generates an UUID (Version 4) per RFC 4122 using crypto API. */
export function uuid4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		// eslint-disable-next-line no-bitwise
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
	);
}

/**
 * Add the the specified name value pair to the url.
 * Why not use URLSearchParams? Well I don't want to pay the cost to instantiate
 * it and then there are the ${@link gotchas|https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#gotchas}
 */
export function add_query_parameter(url, name, value) {
	if (!!name && !!value) {
		// begin query string unless we already have one
		if (!url.includes('?')) url += '?';

		// respect existing parameters (relies on previous condition)
		if (url.charAt(url.length - 1) !== '?' || url.includes('&')) url += '&';

		url += `${name}=${value}`;
		// url = encodeURI(url); // too costly in looping usages
	}
	return url;
}

// export function add_query_parameter_list(url, params) {
//     return Object.entries(params).reduce(
//         (acc, [key, value]) => add_parameter(acc, key, value),
//         url,
//     );
// }

/* Helper function that returns width and height of current viewport. */
export function get_viewport() {
	const document_element = (window.document && window.document.documentElement) || {};
	return {
		width: Math.max(document_element.clientWidth || 0, window.innerWidth || 0),
		height: Math.max(document_element.clientHeight || 0, window.innerHeight || 0),
	};
}

export const isDesktop = () => window.matchMedia('(min-width: 900px)').matches;
export const isMobile = () => window.matchMedia('(max-width: 600px)').matches;

/*
		Helper function that returns whether element is in view vertically.
		Returns true if the top of the viewport scrolls to or below the middle of the element.
*/
export function get_out_view(elem) {
	if (!elem) {
		console.log('elem is not defined: ', elem);
		return;
	}
	const desktop = isDesktop();

	// Check whether the user is logged in.
	const adminBar = document.querySelector('.admin-bar');

	// Assess gap above the player starting with floating menu which is 70px on desktop and 65px on mobile.
	let gapAbovePlayer = desktop ? 70 : 65;

	// On desktop the admin bar also falls above the inline player as the user scrolls down.
	if (adminBar && desktop) {
		gapAbovePlayer += 32;
	}

	// Get the size and position properties for the element.
	const rect = elem.getBoundingClientRect();

	/*
		Find the current distance from the top of the viewable area to the top of the player.
		This will be negative if the inline player has passed above the top of the viewable window.
	*/
	const top = rect.top - gapAbovePlayer;
	// Get the current position on the page of the middle of the player.
	const mid = top + rect.height / 2;
	// Has the user scrolled past the middle of the inline position of the video yet?
	const passedMid = mid < 0;

	return passedMid;
}

/*
		Helper function that converts object keys with hyphens (-) to camelCase.
		Intended to convert CSS style objects to React style objects.
*/
export function as_react_style(style_obj) {
	return Object.keys(style_obj).reduce((acc, key) => {
		const react_key = key
			.split('-')
			.map((str, index) => (index ? str.charAt(0).toUpperCase() + str.slice(1) : str))
			.join('');
		acc[react_key] = style_obj[key];
		return acc;
	}, {});
}

export const VIDEO_TYPES = {
	VIDEO: 'video',
	PLAYLIST: 'playlist',
	LIVESTREAM: 'livestream',
};

/*
		Helper function that tries to infer video type based on video ID.
		Note that the information returned is not the most reliable, but the best available.
*/
export function infer_video_type(video_id) {
	/*
				Check if ID is numeric.
				If it is, it's a video ID.
				If it is not, it's either livestream, or a playlist.
				Livestreams contain lowercase whereas playlists are all uppercase.
		*/
	if (!isNaN(video_id)) {
		return VIDEO_TYPES.VIDEO;
	}

	if (String(video_id) === String(video_id).toUpperCase()) {
		return VIDEO_TYPES.PLAYLIST;
	}

	return VIDEO_TYPES.LIVESTREAM;

	/* Default value set, though should not be possible to reach. */
	// return VIDEO_TYPES.VIDEO;
}

/**
 * Helper function that transforms JSONP response into JSON
 * @param {string} jsonpString String representation of JSONP
 * @returns Object has parameters of JSONP as fields
 */
export const extractJSON = (jsonpString) =>
	JSON.parse(jsonpString.substring(jsonpString.search(RE_JSON) + 1, jsonpString.length - 1));

/**
 * Helper function that returns the images link from our cdn instead of
 * Anvato's api response
 * @param {string} original The original url
 * @param {*} replaced The replaced url
 * @returns replaced url
 * Not used now.
 */
export const replaceUrl = (original, replaced = IMAGE_DOMAIN) => {
	const domain = new URL(original);
	return original.replace(domain.hostname, replaced);
};

/**
 * Helper function that is to extract query params from the URL object
 * @param {object} location The URL object to extract
 * @returns query params as string
 */
export const getQueryParamsFromURL = (url) => new URLSearchParams(url.search) || {};

/**
 * Helper function that identify if the parameter whether is an object or not
 * @param {*} value
 * @returns whether the value is object or not
 */
export const isObject = (value) => typeof value === 'object' && value !== null;

/**
 * Shape each event and use a fixed structure for events that will
 * Be dispatched to the window.
 */
export const shapeOVPEvent = (eventName, playerId, args) =>
	new CustomEvent('OVPPlayerEvent', {
		detail: {
			eventName,
			playerId,
			args,
		},
	});

/**
 * Helper function that emits events for analytics, player.el_.dispatchEvent will be
 * Deprecated, and window.dispatchEvent will be used instead.
 */
export const dispatchCustomEvent = (player, eventName) => {
	const analyticsPayload = analyticsPayloadDict[player.id()];

	// Checks against liveStream denyList to see if the event should be emitted
	if (analyticsPayload && player.isLivestream && LIVESTREAM_EVENT_DENY_LIST.includes(eventName)) {
		return;
	}

	// We may be able to get rid of the isLive() check, but keeping for now since I don't fully trust the two classes.
	const readyToResetPlayhead =
		playheadReset === false &&
		player.isLivestream &&
		player.el_.classList.contains('vjs-live') &&
		player.el_.classList.contains('vjs-playing');

	if (analyticsPayload) {
		// One time check to reset the playhead position
		if (readyToResetPlayhead) {
			playheadReset = true;
			analyticsPayload.playheadPosition = 0;
			// If we've reset the playhead, we have to catch the time offset immediately after or we may miss it.
			if (!timeOffsetUpdated) {
				// Sometimes the currentTime is 3 or 10, in which case we want to skip creating an offset.
				if (player.currentTime() > 10) {
					// Using the next currentTime update after playhead reset to capture how off currentTime is.
					timeOffset = Math.round(player.currentTime());
				}
				timeOffsetUpdated = true;
			}
		}

		// To ensure playheadPosition doesn't include ad time, any time an ad starts
		// it should be added to the time offset. (Duration changes by 'Complete' event.)
		if (player?.isLivestream && (player.currentTime() > 10) && eventName === 'adStart') {
			timeOffset += analyticsPayload.adDuration;
		}

		// Additionally, let's prevent playheadPosition updates during ads.
		const adsPlaying = !!analyticsPayload.adPodPosition;
		if (!adsPlaying) {
			const newPlayheadPosition = Math.round(player.currentTime() - timeOffset);
			/**
			 * timeOffset will be 0 if this isn't livestream, and we'll set position to 0 if position is negative.
			 * It can end up negative in the first few seconds because of a weird pattern when player starts.
			 * This pattern goes something like this (if offset is 70 for example):
			 * 70, 70, 0, 0, 70, 70, 70, 70, 70, 80, 90, 100
			 * So after offset calculation, it would be 0, 0, -70, -70, 0, 0, 0, 0, 0, 10, 20, 30
			 * The < 0 check makes sure we just send 0 instead of these initial glitchy negative values.
			 */
			analyticsPayload.playheadPosition = newPlayheadPosition < 0 ? 0 : newPlayheadPosition;
		}
		// Sync player volume with analyticsPayload volume.
		if (!player.volume() && analyticsPayload.volume) {
			player.volume(analyticsPayload.volume / 100)
		}
		if (player.muted()) {
			analyticsPayload.muted = true;
			analyticsPayload.volume = 0;
		} else {
			analyticsPayload.volume = Math.round(player.volume() * 100);
		}
	}

	const event = new CustomEvent(eventName, { detail: analyticsPayload });
	const shapedEvent = shapeOVPEvent(eventName, player.id(), analyticsPayload);
	player.el_.dispatchEvent(event);
	window.dispatchEvent(shapedEvent);
};

/**
 * Helper function that fills the analytics payload with the data from Anvato response
 */
export const setAnalyticsPayloadUsingAnvatoResponse = (analyticsPayload, videoInfo) => {
	const livestream = analyticsPayload.livestream;
	const airDateVal = videoInfo.airdate * 1000;
	analyticsPayload.airDate = !isNaN(airDateVal)
		? new Date(airDateVal).toISOString()
		: new Date().toISOString();
	analyticsPayload.videoId = videoInfo.id;
	analyticsPayload.framerate = videoInfo.framerate;
	// This should always be false on livestream.
	analyticsPayload.fullEpisode = livestream ? false : parseInt(videoInfo.episodeNo, 10) !== 0;
	analyticsPayload.program = videoInfo.programName || 'News';
	analyticsPayload.videoDuration = videoInfo.videoDuration || null;
	analyticsPayload.videoDurationTime =
		analyticsPayload.videoDuration && !isNaN(analyticsPayload.videoDuration)
			? new Date(analyticsPayload.videoDuration * 1000).toISOString()?.substring(11, 19)
			: null;
	analyticsPayload.videoTitle = videoInfo.title;
	analyticsPayload.originalVideoTitle = videoInfo.title;
	analyticsPayload.adPodType = '';
	analyticsPayload.closedCaptions = false;
	analyticsPayload.videoStartCount = 0;
};

export const getCookie = (name) => {
	const m = window.document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]*)\\s*(;|$)`);
	return m ? decodeURIComponent(m[2]) : undefined;
};

export const getItemFromSessionStorage = (key) => {
	let retrievedItem;
	try {
		retrievedItem = JSON.parse(sessionStorage.getItem(key));
	} catch (e) {
		retrievedItem = null;
	}
	return retrievedItem;
};

export const determineShareType = (socialMediaType) => SOCIAL_MEDIA_TYPES[socialMediaType];

const handleBeaconTracking = (trackingDataURL) => {
	if (trackingDataURL) axios.get(trackingDataURL);
};

const handleMultipleBeaconTracking = (trackingDataURLs) => {
	trackingDataURLs?.forEach((url) => handleBeaconTracking(url));
};

export const addOneTimeEvents = (player, ads, player_id, player_name) => {
	const analyticsPayload = analyticsPayloadDict[player.id()];
	dispatchCustomEvent(player, 'playbackStart');
	const playbackStartTracker = new SimpleErrorTracker('playbackStart', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
	});
	playbackStartTracker.sendSuccess();
	const clickEvent = isMobile() ? 'touchend' : 'click';
	player.el_.querySelector('video').addEventListener(clickEvent, () => {
		// Prevent playbackResume event triggering before recommendation video starts
		// By adding player.currentTime() check
		if (!player.paused() && player.currentTime()) dispatchCustomEvent(player, 'playbackResume');
		else if (!player.ended()) dispatchCustomEvent(player, 'playbackPause');
	});
	player.el_.querySelector('.vjs-play-control').addEventListener(clickEvent, (e) => {
		if (!Array.from(e.target?.parentNode?.classList)?.includes('vjs-ended')) {
			if (!player.paused()) dispatchCustomEvent(player, 'playbackResume');
			else dispatchCustomEvent(player, 'playbackPause');
		}
	});
	const shareControl = player.controlBar.el_.querySelector('.vjs-share-control');
	if (shareControl) {
		shareControl.addEventListener(clickEvent, () => {
			dispatchCustomEvent(player, 'videoShareOpenTools');
			player.el_.querySelectorAll('.vjs-share__social').forEach((socialShareButton) => {
				socialShareButton.addEventListener(clickEvent, (e) => {
					const socialMediaType = determineShareType(e.currentTarget.dataset?.social);
					if (analyticsPayload) {
						analyticsPayload.shareType = socialMediaType;
						analyticsPayload.shareUrl = window.location.href;
					}
					dispatchCustomEvent(player, 'videoShare');
				});
			});
		});
	}
	// Logger playback events
	oldVideoStart(player, ads, player_id, player_name);
};

// In order to reset analytics payload after recommendation video plays
export const resetAnalyticsPayloadDict = (videoInfo, playerId) => {
	const analyticsPayload = analyticsPayloadDict[playerId];
	if (!analyticsPayload || !videoInfo) return;
	const {
		id,
		title,
		videoDuration = null,
		airdate,
		full_episode,
		programName = 'News',
		url,
		framerate,
		livestream,
	} = videoInfo || {};
	const airDateVal = airdate * 1000;
	analyticsPayload.airDate = !isNaN(airDateVal)
		? new Date(airDateVal).toISOString()
		: new Date().toISOString();
	analyticsPayload.videoId = id;
	analyticsPayload.videoTitle = title;
	analyticsPayload.originalVideoTitle = title;
	analyticsPayload.videoDuration = videoDuration;
	analyticsPayload.videoDurationTime =
		analyticsPayload.videoDuration && !isNaN(analyticsPayload.videoDuration)
			? new Date(analyticsPayload.videoDuration * 1000).toISOString()?.substring(11, 19)
			: null;
	analyticsPayload.fullEpisode = livestream ? false : full_episode; // This should always be false on livestream.
	analyticsPayload.program = programName;
	analyticsPayload.videoUrl = url;
	analyticsPayload.framerate = framerate;
	analyticsPayload.videoInitType = videoInfo.is_manually_triggered ? 'manual' : 'auto';
	analyticsPayload.recommendationIndex = videoInfo.recommendation_index;
	analyticsPayload.loadMethod = videoInfo.load_method;
};

/**
 * Replace multiple substrings according to a map object.
 *
 * The map should consist of key value pairs where the key equals the substring
 * to be replaced, and the value equals the replacement string.
 *
 * @link https://stackoverflow.com/a/15604206/1801260
 *
 * @param {string} subject
 * @param {object} map
 * @returns string
 */
export const replaceMultiple = (subject, map) => {
	const pattern = new RegExp(Object.keys(map).join('|'), 'gi');
	return subject.replace(pattern, (matched) => map[matched]);
};

/**
 * Update the 'cust_params' in a VAST URL with the given key-value pairs,
 * deleting empty parameters from the completed URL.
 *
 * @param {Object} newParams New key-value pairs.
 * @param {URL} oldURL Previous VAST URL.
 * @returns {URL} Updated VAST URL.
 */
export const custParamsUrl = (newParams, oldURL) => {
	if (!Object.keys(newParams).length) {
		const out = new URL(oldURL.toString());
		return decodeURIComponent(out.toString());
	}

	const tabInFocus = document.visibilityState === 'visible';

	const newStr = replaceMultiple(
		new URLSearchParams(
			// First ensure that all keys are overwritten with new params, then filter.
			Object.entries({
				...Object.fromEntries(
					new URLSearchParams(oldURL.searchParams.get('cust_params')).entries(),
				),
				...newParams,
				vpif: tabInFocus ? 'true' : 'false',
			}).reduce(
				// eslint-disable-next-line arrow-body-style
				(carry, pair) => {
					return pair[1]
						? {
							...carry,
							[pair[0]]: pair[1],
						}
						: carry;
				},
				{},
			),
		).toString(),
		// `cust_params` needs special encoding.
		{
			'=': '%3D',
			'&': '%26',
			',': '%2C',
		},
	);

	const IS_FREEWHEEL = window.nxstVideoDeliveryAdProvider === 'freewheel';
	const IS_FREEWHEEL_AMP = IS_FREEWHEEL && window.nxstVideoDeliveryIsAmp;
	const ADD_CUST_PARAMS = !IS_FREEWHEEL && !IS_FREEWHEEL_AMP;

	const out = new URL(oldURL);
	out.searchParams.delete('cust_params');
	const custParams = ADD_CUST_PARAMS ? `&cust_params=${newStr}` : '';

	return `${decodeURIComponent(out.toString())}${custParams}`;
};

/**
 * Update ad tag's cust_params query parameter with the duration of the current video and video id
 *
 * @param {Object} ads Ad object
 * @param {Object} cust_params Object that includes params we want to add to custParams
 * @param {Object} additional_params Object that includes query params we want to add to the ad tag url
 * @param {Boolean} isMute If the player is muted or not
 * @returns {Object} updated ad object
 */
export const updateAdRequest = (ads, cust_params, additional_params = {}, isMute = true) => {
	if (!ads.ad_tag_url) return;
	const adTagUrl = new URL(ads.ad_tag_url);

	if (!ads.isFreewheel) {
		additional_params = {
			...additional_params,
			playbackmethod: isMute ? 2 : 1,
			vpmute: isMute ? 1 : 0,
		}
	}

	if (Object.keys(additional_params)?.length)
		Object.entries(additional_params).forEach((item) => {
			adTagUrl.searchParams.set(item[0], item[1]);
		});
	ads = { ...ads, ad_tag_url: custParamsUrl(cust_params, adTagUrl) };
	return ads;
};

/**
 * Helper function that emits custom player events, we are not using window.dispatchEvent
 * Since this function is fired/caught within the player
 * @param {Object} player instance of the current player
 * @param {String} eventName
 */
export const dispatchPlayerEvent = (player, eventName = '') => {
	const event = new CustomEvent(eventName);
	player.el_.dispatchEvent(event);
};

// Dispatch overlay active event for videojs-overlay to listen
export const dispatchOverlayActiveEvent = (player) => {
	dispatchPlayerEvent(player, 'overlayActive');
};

// Dispatch overlay inactive event videojs-overlay to listen
export const dispatchOverlayInactiveEvent = (player) => {
	dispatchPlayerEvent(player, 'overlayInactive');
};

export const videoStartDispatcher = (player, ads, player_id, player_name) => {
	const loggerFn = (isSuccess, eventCase = 1) => {
		const videoStartTracker = new SimpleErrorTracker('videoStart', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
		});
		if (isSuccess) {
			videoStartTracker.sendSuccess();
		} else {
			videoStartTracker.sendError(
				{
					name: 'videoStart else condition called',
					message: {
						eventCase,
					},
				},
				1,
			);
		}
	};

	player.one('play', () => {
		if (window.isAdBlockerActive) {
			const adBlockerTracker = new SimpleErrorTracker('Player detected content blocker', 100, {
				id: player_id,
				position: player_name,
				renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
			});
			adBlockerTracker.sendSuccess();
		}
		setTimeout(() => {
			if (
				(window.isAdBlockerActive || !window.google || ads.disable_ads) &&
				!player.videoStartAlreadyTriggered
			) {
				dispatchCustomEvent(player, 'videoStart');
				player.videoStartAlreadyTriggered = true;
				loggerFn(true);
			} else {
				loggerFn(false, 1);
			}
		}, 250);
	});
	player.one('bufferStart', () => {
		player.one(['adend', 'adtimeout', 'adserror'], () => {
			// Force hide the ad container when the content starts
			const adContainer = document.getElementById(`${player_id}_ima-ad-container`);
			if (adContainer) adContainer.style.display = 'none';
			if (!player.videoStartAlreadyTriggered) {
				setTimeout(() => dispatchCustomEvent(player, 'videoStart'));
				player.videoStartAlreadyTriggered = true;
				loggerFn(true);
			} else {
				loggerFn(false, 2);
			}
		});
	});
};

export const detectBrowser = () => {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
		return 'Opera';
	}
	if (navigator.userAgent.indexOf('Chrome') !== -1) {
		return 'Chrome';
	}
	if (navigator.userAgent.indexOf('Safari') !== -1) {
		return 'Safari';
	}
	if (navigator.userAgent.indexOf('Firefox') !== -1) {
		return 'Firefox';
	}
	if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
		return 'IE';
	}
	return 'Unknown';
};

const parse = (line) => {
	const clickThroughURL = line.split(':')[1];
	const statements = clickThroughURL.trim().split(',');
	const result = {};
	for (let i = 0; i < statements.length; i++) {
		const [key, value] = statements[i].split('=');
		result[key] = value;
	}
	if (result.json) {
		result.json = JSON.parse(window.atob(result.json));
	}
	return result;
};

const handleClickThrough = (trackingDataURL, duration, player, anvInfoType) => {
	player.clickBeaconObj.anvInfoType = anvInfoType;
	player.clickBeaconObj.clickThroughURL = trackingDataURL;
};

const handleStart = (trackingDataURL) => {
	setTimeout(() => handleBeaconTracking(trackingDataURL), 50);
};

const handleFirstQuartile = (trackingDataURL, duration) => {
	setTimeout(() => {
		handleBeaconTracking(trackingDataURL);
	}, Math.round(duration / 4) * 1000);
};

const handleMidPoint = (trackingDataURL, duration) => {
	setTimeout(() => {
		handleBeaconTracking(trackingDataURL);
	}, Math.round(duration / 2) * 1000);
};

const handleThirdQuartile = (trackingDataURL, duration) => {
	setTimeout(() => {
		handleBeaconTracking(trackingDataURL);
	}, Math.round((3 * duration) / 4) * 1000);
};

const handleComplete = (trackingDataURL, duration) => {
	setTimeout(() => {
		handleBeaconTracking(trackingDataURL);
	}, duration * 1000);
};

const handleImpressions = (trackingDataURL) => {
	setTimeout(() => handleBeaconTracking(trackingDataURL));
};

const handleClickTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.clickTrackingURLs.push(trackingDataURL);
};

const handleMuteTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.muteURLs.push(trackingDataURL);
};

const handleUnmuteTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.unmuteURLs.push(trackingDataURL);
};

const handlePauseTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.pauseURLs.push(trackingDataURL);
};

const handleResumeTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.resumeURLs.push(trackingDataURL);
};

const handleFullscreenTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.fullscreenURLs.push(trackingDataURL);
};

const handleExitFullscreenTracking = (trackingDataURL, duration, player) => {
	player.clickBeaconObj.exitFullscreenURLs.push(trackingDataURL);
};

const handleMidrollAnalytics = (player, currentMidrollMetadata, duration, index) => {
	clearInterval(adHeartbeatID);
	// Consistent way to have adComplete fire without a timeout, before the ad info updates.
	if (index > 0) {
		dispatchCustomEvent(player, 'adComplete');
	}

	const analyticsPayload = analyticsPayloadDict[player.id()];
	analyticsPayload.adId = currentMidrollMetadata?.ad_id;
	analyticsPayload.adTitle = currentMidrollMetadata?.ad_title;
	analyticsPayload.adDuration = duration;
	analyticsPayload.adDurationTime = !isNaN(duration)
		? new Date(duration * 1000).toISOString()?.substring(11, 19)
		: undefined;

	// Ad pod position needs to start at 1.
	analyticsPayload.adPodPosition = index + 1;

	if (index === 0) {
		dispatchCustomEvent(player, 'adBreakStart');
	}

	dispatchCustomEvent(player, 'adStart');

	adHeartbeatID = setInterval(() => {
		dispatchCustomEvent(player, 'adHeartbeat');
	}, adHeartbeatInterval * 1000);
};

const liveStreamBeacons = {
	ClickThrough: handleClickThrough,
	ClickTracking: handleClickTracking,
	start: handleStart,
	creativeView: handleStart,
	firstQuartile: handleFirstQuartile,
	midpoint: handleMidPoint,
	thirdQuartile: handleThirdQuartile,
	complete: handleComplete,
	impression: handleImpressions,
	mute: handleMuteTracking,
	unmute: handleUnmuteTracking,
	pause: handlePauseTracking,
	resume: handleResumeTracking,
	fullscreen: handleFullscreenTracking,
	exitFullscreen: handleExitFullscreenTracking,
};

const callBeaconAction = (beaconType, trackingDataURL, duration, player, anvInfoType) => {
	const callback = liveStreamBeacons?.[beaconType];
	if (!callback) return;
	callback(trackingDataURL, duration, player, anvInfoType);
};

const resetPlayerBeacons = (player) => {
	player.clickBeaconObj = {
		anvInfoType: undefined,
		clickThroughURL: undefined,
		clickTrackingURLs: [],
		muteURLs: [],
		unmuteURLs: [],
		pauseURLs: [],
		resumeURLs: [],
		fullscreenURLs: [],
		exitFullscreenURLs: [],
	};
};

export const showMidrollOverlay = (player, showTitleOverlay) => {
	// hide the previous Overlay
	if (showTitleOverlay) {
		dispatchOverlayInactiveEvent(player);
	}
	// implement the ad message
	player.overlay({
		overlays: [
			{
				class: 'ad-text-overlay',
				start: 'adOverlayActive',
				end: 'adOverlayInactive',
				align: 'top-left',
				content: adText,
			},
			{
				class: 'ad-text-overlay-learn_more',
				start: 'adOverlayActive',
				end: 'adOverlayInactive',
				align: 'top-right',
				content: 'Learn More',
				showBackground: false,
			},
		],
	});

	const target = document.querySelector('.ad-text-overlay-learn_more');
	// Bind tracking events for beacons
	// Using player.clickBeaconObj.anvInfoType === 'ad' check directly for each event
	// Since assigning it to a variable keeps stale in triggered event's scope
	if (target) {
		target.addEventListener('click', () => {
			if (player.clickBeaconObj.anvInfoType === 'ad') {
				player.play();
				window.open(player.clickBeaconObj.clickThroughURL, '_blank')?.focus();
				if (player.clickBeaconObj.clickTrackingURLs.length)
					handleMultipleBeaconTracking(player.clickBeaconObj.clickTrackingURLs);
				dispatchCustomEvent(player, 'adClick');
			}
		});
	}
	// show the ad message
	dispatchPlayerEvent(player, 'adOverlayActive');
};

export const hideMidrollOverlay = (player, showTitleOverlay, overridedTitle, videoTitle) => {
	if (player.overlays_?.[0]?.options_?.content !== adText) {
		// dont run if the adtext is not present
		return;
	}
	// hide the ad message
	dispatchPlayerEvent(player, 'adOverlayInactive');
	// if there was a previews message we should display it
	if (showTitleOverlay) {
		player.overlay({
			content: overridedTitle || videoTitle,
			align: 'top-left',
			overlays: [
				{
					start: 'overlayActive',
					end: 'overlayInactive',
				},
			],
		});
		dispatchOverlayActiveEvent(player);
	}
};

export const addCustomTagParsers = (player, showTitleOverlay, overridedTitle, videoTitle) => {
	const is_safari = detectBrowser() === 'Safari';

	// Create beacon object that is unique to the player
	player.on('loadedmetadata', () => {
		player.isPreviouslyMuted = player.muted();
		player.clickBeaconObj = {
			anvInfoType: undefined,
			clickThroughURL: undefined,
			clickTrackingURLs: [],
			muteURLs: [],
			unmuteURLs: [],
			pauseURLs: [],
			resumeURLs: [],
			fullscreenURLs: [],
			exitFullscreenURLs: [],
		};

		player.on('volumeMute', () => {
			if (player.isPreviouslyMuted) return;
			player.isPreviouslyMuted = true;
			if (player.clickBeaconObj.anvInfoType === 'ad' && player.clickBeaconObj.muteURLs.length) {
				handleMultipleBeaconTracking(player.clickBeaconObj.muteURLs);
			}
		});

		player.on('volumeChange', () => {
			if (player.volume() === 0) {
				player.muted(true);
			}
			if (
				player.clickBeaconObj.anvInfoType === 'ad' &&
				player.clickBeaconObj.unmuteURLs.length &&
				player.isPreviouslyMuted &&
				!player.muted()
			) {
				handleMultipleBeaconTracking(player.clickBeaconObj.unmuteURLs);
			}
			if (!player.muted()) {
				player.isPreviouslyMuted = false;
			}
		});

		player.on('playbackPause', () => {
			if (player.clickBeaconObj.anvInfoType === 'ad') { // Could remove this, but safer to be precise.
				clearInterval(adHeartbeatID);
			}
			if (player.clickBeaconObj.anvInfoType === 'ad' && player.clickBeaconObj.pauseURLs.length) {
				handleMultipleBeaconTracking(player.clickBeaconObj.pauseURLs);
			}
		});

		player.on('playbackResume', () => {
			const analyticsPayload = analyticsPayloadDict[player.id()];
			// Make sure we're in midrolls.
			if (player.clickBeaconObj.anvInfoType === 'ad' && analyticsPayload.adPodPosition) {
				// If we are, restart the adHeartbeat.
				adHeartbeatID = setInterval(() => {
					dispatchCustomEvent(player, 'adHeartbeat');
				}, adHeartbeatInterval * 1000);
			}
			if (player.clickBeaconObj.anvInfoType === 'ad' && player.clickBeaconObj.resumeURLs.length) {
				handleMultipleBeaconTracking(player.clickBeaconObj.resumeURLs);
			}
		});

		player.on('fullscreenOn', () => {
			if (
				player.clickBeaconObj.anvInfoType === 'ad' &&
				player.clickBeaconObj.fullscreenURLs.length
			) {
				handleMultipleBeaconTracking(player.clickBeaconObj.fullscreenURLs);
			}
		});

		player.on('fullscreenOff', () => {
			if (
				player.clickBeaconObj.anvInfoType === 'ad' &&
				player.clickBeaconObj.exitFullscreenURLs.length
			) {
				handleMultipleBeaconTracking(player.clickBeaconObj.exitFullscreenURLs);
			}
		});

		// Safari only supports native video element, that is why the implementation
		// Differs for safari.
		if (is_safari) {
			const cueManager = new CueManager();
			const video = player.el_.querySelector('video');
			cueManager.attachMedia(video);

			const handleId3 = (cueDataObj) => {
				if (cueDataObj?.key === 'PRIV' && cueDataObj?.info.includes('www.nielsen.com') && window?.NOLBUNDLE?.nlsnInstance?.initialized) {
					const analyticsPayload = analyticsPayloadDict[player.id()];
					analyticsPayload.id3Tag = cueDataObj?.info;
					analyticsPayload.id3Tag = cueDataObj?.info;
					analyticsPayload.adloadtype = 'linear';
					player.id3metadata = cueDataObj?.info;
					window?.NOLBUNDLE?.nlsnInstance.ggPM("sendID3", cueDataObj?.info);
				}
			}

			video.textTracks.addEventListener('addtrack', (e) => {
				console.log({ type: 'adtrack', e });
				if (e.track.kind === 'metadata') {
					cueManager.attachTrack(e.track, handleId3);
				}
			});

			cueManager.addEventListener('cuechange', (e) => {
				const activeCue = e.target.getActiveCue();
				if (!activeCue) return;
				const { ad, type: anvInfoType } = activeCue || {};
				const { metaUrl, index, duration } = ad || {};
				if (metaUrl) {
					// show the ad message
					showMidrollOverlay(player, showTitleOverlay);
					resetPlayerBeacons(player);
					axios.get(metaUrl).then((res) => {
						handleMidrollAnalytics(player, res.data?.[index], duration, index);
						res.data?.[index]?.tracking.forEach(({ type, url }) => {
							callBeaconAction(type, url, duration, player, anvInfoType);
						});
					});
				} else if (anvInfoType !== 'ad' && player.clickBeaconObj.anvInfoType) {
					const analyticsPayload = analyticsPayloadDict[player.id()];
					clearInterval(adHeartbeatID);
					dispatchCustomEvent(player, 'adComplete'); // Handles the last midroll ad even if the break ends early.
					dispatchCustomEvent(player, 'adBreakComplete');
					delete analyticsPayload.adPodPosition;
					dispatchCustomEvent(player, 'playbackResume');
					// hide the ad message
					hideMidrollOverlay(player, showTitleOverlay, overridedTitle, videoTitle);
					player.clickBeaconObj.anvInfoType = undefined;
				}
			});
		} else {
			player.tech().vhs?.playlists.customTagParsers.push({
				expression: /^#ANVATO-SEGMENT-INFO:/,
				customType: 'anvinfo',
				dataParser: parse,
				segment: true,
			});

			player.tech().vhs?.playlists.customTagParsers.push({
				expression: /^#ANVATO-STREAM-CUE:/,
				customType: 'anvcue',
				dataParser: parse,
				segment: true,
			});
			player.tech().vhs?.playlists.customTagParsers.push({
				expression: /^#ANVATO-STREAM-CUE-SEC:/,
				customType: 'anvcuesec',
				dataParser: parse,
				segment: true,
			});

			const tracks = player.textTracks();
			let segmentMetadataTrack;
			for (let i = 0; i < tracks.length; i++) {
				if (tracks[i].label === 'Timed Metadata') {
					segmentMetadataTrack = tracks[i];
				}
			}

			if (segmentMetadataTrack) {
				segmentMetadataTrack.on('cuechange', () => {
					const activeCue = segmentMetadataTrack.activeCues[0];
					if (activeCue && 'frame' in activeCue && activeCue.frame !== undefined) {
						const cueDataObj = activeCue.frame;
						if (cueDataObj?.key === 'PRIV' && cueDataObj?.owner.includes('www.nielsen.com') && window?.NOLBUNDLE?.nlsnInstance?.initialized) {
							const analyticsPayload = analyticsPayloadDict[player.id()];
							analyticsPayload.id3Tag = cueDataObj?.owner;
							analyticsPayload.id3Tag = cueDataObj?.owner;
							analyticsPayload.adloadtype = 'linear';
							player.id3metadata = cueDataObj?.owner;
							window?.NOLBUNDLE?.nlsnInstance.ggPM("sendID3", cueDataObj?.owner);
						}
					}
				});
			}

			player.textTracks()?.[0]?.on('cuechange', () => {
				const value = player.textTracks()?.[0]?.activeCues_?.[0]?.value;
				if (!value) return;
				const { custom } = value || {};
				if (!custom) return;
				const { anvcue, anvinfo } = custom || {};
				// metadata for the new ad
				if (anvcue) {
					// show the ad message
					showMidrollOverlay(player, showTitleOverlay);
					resetPlayerBeacons(player);
					const { vast_meta_url, idx_in_pod, duration, sequence } = anvcue.json || {};
					if (!vast_meta_url) return;
					axios.get(vast_meta_url).then((res) => {
						const index = idx_in_pod || sequence - 1;
						handleMidrollAnalytics(player, res.data?.[index], duration, index);
						res.data?.[index]?.tracking.forEach(({ type, url }) => {
							callBeaconAction(type, url, duration, player, anvinfo?.type);
						});
					});
				} else if (anvinfo?.type !== 'ad' && player.clickBeaconObj.anvInfoType) {
					const analyticsPayload = analyticsPayloadDict[player.id()];
					clearInterval(adHeartbeatID);
					dispatchCustomEvent(player, 'adComplete'); // Handles the last ad including if the break ends early.
					dispatchCustomEvent(player, 'adBreakComplete');
					delete analyticsPayload.adPodPosition;
					dispatchCustomEvent(player, 'playbackResume');
					// hide the ad message
					hideMidrollOverlay(player, showTitleOverlay, overridedTitle, videoTitle);
					player.clickBeaconObj.anvInfoType = undefined;
				}
			});
		}
	});
};

export const determineCorrectEvent = (livestreamEvents) => {
	const now = new Date().getTime();
	let upcomingEvent;

	const currentEvent = Object.values(livestreamEvents)?.find((event) => {
		if (!Object.keys(event).length && !Array.isArray(event)) return false;

		if (Array.isArray(event)) {
			upcomingEvent = event.find((ue) => now >= ue?.ts_start * 1000 && now <= ue?.ts_end * 1000);
			return !!upcomingEvent;
		}

		return now >= event?.ts_start * 1000 && now <= event?.ts_end * 1000;
	});

	return upcomingEvent || currentEvent;
};

// Makes sure the minimized player title fits nicely and adds ellipses.
export function truncateMinTitle() {
	const minTitle = document.querySelector('.min-title');
	if (minTitle?.textContent && minTitle.clientHeight >= 50) {
		let text1 = minTitle.textContent.trim();
		let index1 = text1.lastIndexOf(' ');
		while (minTitle.clientHeight >= 50) {
			text1 = text1.substring(0, text1.length - 4);
			text1 = text1.substring(0, index1);
			index1 = text1.lastIndexOf(' ');
			text1 += ' ...';
			minTitle.textContent = text1;
		}
	}
}


/**
	* check for the ima sdk variables loaded by the scrips
	* @returns {boolean} ads can be run
	*/
export function canRunAds() {
	// the ima sdk scripts files are loaded sync and video delivery depends on them
	// that asures that this variables are defined when we call them
	// eslint-disable-next-line no-prototype-builtins
	return window.google?.hasOwnProperty('ima');
}
